import React, { useState, useEffect } from "react"
import { Link } from "gatsby"

// Header component
import { useStaticQuery, graphql } from "gatsby"

import {externalURL, replaceString, addTrailingSlash} from "../common/utils"
import {gtag_report_conversion} from "../common/pageUtils"
import { useLocation } from "@reach/router";

const FormRightside = (props) => {
    const location = useLocation();
    //console.log("props_location", props.location);
    var page_url = location.href;

    // useEffect(()=>{
    //     var url = location.href;
    //     var gclid = null;
    //     var cellNumber = process.env.GATSBY_GCILD_PHONE;
    //     var isGCLID = 'isgclid';
    //     var cellElement = $("#phone_number");

    //     url = url.toLowerCase();
    //     gclid = localStorage.getItem(isGCLID);

    //     if(url.indexOf('gclid') != -1 || url.indexOf('gcid') != -1){
    //         localStorage.setItem(isGCLID, 'true');
    //         gclid = true;
    //     }

    //     if(gclid && cellNumber.length){
    //         cellElement.attr('href', 'tel:'+cellNumber.replace(/ /g, ''))
    //         //cellElement.text(cellNumber);
    //     }
    //     //console.log("cellElement", cellElement);

    //     cellElement.show();
        
    // },[props?.Data])

    return (
        <React.Fragment>
            <div className="interest">
                <h2>May also interest you</h2>
                {
                    props.Data.map((item, i) => {
                        var cta_label = replaceString(item.CTA_Label);
                        return(
                            item.CTA_Link === "Megamenu" ?
                                <a href={"javascript:;"} key={i} onClick={()=> {props.openSearch(item.CTA_Label); gtag_report_conversion()}} className="btn btn-primary">
                                    {item.Icon_ClassName && <i className={`${item.Icon_ClassName.replace(/_/g,'-')}`}></i>}
                                    {cta_label}
                                </a>
                            :
                                item?.CTA_Link?.indexOf('tel:') > -1 ?
                                    <a href={item?.CTA_Link} key={i}  className="btn btn-primary phone_number" onClick={()=> {gtag_report_conversion()}}>
                                        {item.Icon_ClassName && <i className={`${item.Icon_ClassName.replace(/_/g,'-')}`}></i>}
                                        {cta_label}
                                    </a>
                                :
                                    (item?.CTA_Link?.indexOf('request-an-appraisal') || item?.CTA_Link?.indexOf('register-your-search') > -1) ?
                                        <Link key={i} to={item.CTA_Link ? addTrailingSlash(item.CTA_Link) : 'javascript:;'} state={{"department":props.department ? props.department : "", "page_url": page_url, "type":props.type, "sub_type":props.sub_type, "to_user_id":props.to_user_id}} target={externalURL(item.CTA_Link) ? "_blank" : ""} className="btn btn-primary">
                                        {item.Icon_ClassName && <i className={`${item.Icon_ClassName.replace(/_/g,'-')}`}></i>}
                                        {cta_label}
                                        </Link>
                                    :
                                        <Link key={i} to={item.CTA_Link ? addTrailingSlash(item.CTA_Link) : 'javascript:;'} state={{"department":props.department ? props.department : "", "page_url": page_url, "type":props.type, "sub_type":props.sub_type, "to_user_id":props.to_user_id}} target={externalURL(item.CTA_Link) ? "_blank" : ""} className="btn btn-primary" onClick={()=> {gtag_report_conversion(item.CTA_Link)}}>
                                        {item.Icon_ClassName && <i className={`${item.Icon_ClassName.replace(/_/g,'-')}`}></i>}
                                        {cta_label}
                                        </Link>
                        )
                    })
                }
            </div>
        </React.Fragment>
    );
};
export default FormRightside;
