import React, { useState, useEffect } from "react"
import { Link } from "gatsby"

import ScrollAnimation from 'react-animate-on-scroll';
import HTMLReactParser from 'html-react-parser';
import { Container, Row, Col, Tabs, Tab, Form, Button, Select } from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby"
import Form_Rightside from "../../FormBottom/Form_Rightside"
import BookViewing from "../../forms/book-a-viewing"

import "./Connect.scss"
// Header component

const EnquiryForm = (props) => {

    const data = useStaticQuery(graphql`
    query enquiryForm {  
        glstrapi{
            globalModule {
                Property_Details_Enquiry_Form {
                    Content
                    Title
                    May_Also_CTA {
                        CTA_Label
                        CTA_Icon {
                            url
                            alternativeText
                        }
                        CTA_Link
                        Icon_ClassName
                    }
                    Dynamics_Form_Details {
                        Department
                        Form_User_ID
                        id
                    }
                }

                Auction_Details {
                    Form_Content
                    Form_Title
                    May_Also_CTA {
                        CTA_Label
                        CTA_Icon {
                            url
                            alternativeText
                        }
                        CTA_Link
                        Icon_ClassName
                    }
        
                }
            }
        }
    }`)

    var global_module = data.glstrapi?.globalModule;

    var department = (props.propertyData?.department === "auction_commercial" || props.propertyData?.department === "auction_residential" || props.department === "auctions") ? "auctions" : props.propertyData?.department;

    var type = (props.propertyData?.department === "residential" || props.propertyData?.department === "commercial") ? "Agency" : "Auctions";
    var sub_type = (props.propertyData?.department === "residential" || props.propertyData?.department === "commercial") ? "Other" : "Other";

    var to_user_id = global_module?.Property_Details_Enquiry_Form?.Dynamics_Form_Details && global_module?.Property_Details_Enquiry_Form?.Dynamics_Form_Details.length > 0 && global_module?.Property_Details_Enquiry_Form?.Dynamics_Form_Details.filter(item => item.Department.toLowerCase() === department.toLowerCase());

    //console.log("to_user_id", to_user_id);

    return (
        <React.Fragment>
            <section className="connect-us" id="enquire-now">
                <Container>
                    <Row>
                        <Col>
                            <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>
                                <div className="connect-wrapper">
                                    {
                                        global_module && 
                                        <div className="contact-form">
                                            {props.propertyData?.department === "auction_commercial" || props.propertyData?.department === "auction_residential" || props.department === "auctions" ? 
                                            <>
                                                <h2>{global_module?.Auction_Details?.Form_Title}</h2>
                                                {global_module?.Auction_Details?.Form_Content && 
                                                <p>{HTMLReactParser(global_module.Auction_Details.Form_Content)}</p>}
                                            </> : <>
                                                <h2>{global_module?.Property_Details_Enquiry_Form?.Title}</h2>
                                                {global_module?.Property_Details_Enquiry_Form?.Content && HTMLReactParser(global_module.Property_Details_Enquiry_Form.Content)}
                                            </>
                                            }

                                            <BookViewing propertyData={props.propertyData} department={props.department ? props.department : props.propertyData?.department} location={props.location} type={type} sub_type={sub_type} to_user_id={to_user_id.length > 0 ? to_user_id[0].Form_User_ID : ""} />
                                        </div>
                                    }
                                    

                                    {props.propertyData?.department === "auction_commercial" || props.propertyData?.department === "auction_residential" || props.department === "auctions" ? 
                                        global_module.Auction_Details.May_Also_CTA && global_module.Auction_Details.May_Also_CTA.length > 0 && 
                                            <Form_Rightside Data={global_module.Auction_Details.May_Also_CTA} openSearch={props.openSearch} department={props.propertyData?.department}/>

                                    : global_module.Property_Details_Enquiry_Form.May_Also_CTA && global_module.Property_Details_Enquiry_Form.May_Also_CTA.length > 0 && 
                                        <Form_Rightside Data={global_module.Property_Details_Enquiry_Form.May_Also_CTA} openSearch={props.openSearch} department={props.propertyData?.department}/>
                                    }
                                </div>
                                
                            </ScrollAnimation>
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    );
};
export default EnquiryForm;
